import {defaultAxios, uploadAxios} from "./index"
import { Result, ResultWithPager } from "../types/common"
import {
  getUserBasicInfoByPinWithMaskResult, queryTradeOrderListResult,
  queryTradeOrderListParam, paymentChangeParam, paymentReversalParam,
  checkAccountOpeningProgressResult
} from '../models/api/biz.ts';

export async function jdfAccountBalanceQuery(): Promise<Result<{ balance: string }>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/jdfAccountBalanceQuery',
    data: {},
  })
}

/**
 * 新手引导-开户进度查询
 */
// 使用"位"进行表示，每一位表示对应状态是否存在，如 0001 和 0000 表示已实名和未实名，其他状态逻辑同理。最终的状态码值为4位的字符串

// REAL_NAME_Verified(1 << 0, "未实名"), // 0000
// REAL_NAME_Verified(1 << 0, "已实名"), // 0001
// PASSWORD_SET(1 << 1, "已设置交易密码"), // 0010
// APPLY_EXCEEDED(1 << 2, "申请超过限制"), // 0100
// ACCOUNT_EXISTED(1 << 3, "账户已存在"); // 1000

// 举例1：已实名 + 已设置交易密码 + 未超过开户申请限制 + 已开户的返回结果为
// 1011
// 举例2: 已实名 + 未设置交易密码 + 未超过开户申请限制 + 未开户的返回结果为
// 0001
/****上述设计原因： 因为之前的01 02 03 04 05 06是一种状态的扭转，如果未实名 就是01，那么就判断不出来是否开过户；以及是否做新手引导，昨天产品给出的结论是已经开过户的就不再新手引导。但是如果这个人以前开过户，但是实名失效了，那就会卡在01*****/
export async function checkAccountOpeningProgress(): Promise<Result<checkAccountOpeningProgressResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/checkAccountOpeningProgress',
    data: {},
  })
  // return Promise.resolve({
  //   code: '000000',
  //   data: '0001'
  // })
}

/**
 * 设置/手机号邮箱绑定状态
 */
export async function getUserBasicInfoByPinWithMask(): Promise<Result<getUserBasicInfoByPinWithMaskResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/getUserBasicInfoByPinWithMask',
    data: {},
  })
}

export async function payPwdPk(): Promise<{ code: string, msg: string, pk: string }> {
  return defaultAxios({
    method: "post",
    url: "/gw2/generic/jdfpaypwd/h5/m/pk",
    data: {},
  })
}

export type CheckPayPassParam = {
  payPassword: string;
  language?: string;
  country?: string;
}

type CheckPayPassParamResult = Result<any> & {
  checkMsg: string;
  success: string;
  token?: string;
  errorTimes?: number;
}
/**
 * 校验密码
 * https://joyspace.jd.com/pages/TNvrkuiWOA0KjCDY5rRu
 */
export async function checkPayPass(data: CheckPayPassParam): Promise<CheckPayPassParamResult> {
  return defaultAxios({
    method: "post",
    url: "/gw2/generic/jdfpaypwd/h5/m/check",
    data: {
      reqData: data
    },
  })
}



/**
 * 交易列表查询
 */
export async function queryTradeOrderList(params: queryTradeOrderListParam): Promise<ResultWithPager<queryTradeOrderListResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/queryTradeOrderList',
    data: {
      reqData: params
    },
  })
}



/**
 * 付款交易修改
 */
export async function paymentChange(params: paymentChangeParam): Promise<Result<boolean>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/paymentChange',
    data: {
      reqData: params
    },
  })
}


/**
 * 付款交易撤销
 */
export async function paymentReversal(params: paymentReversalParam): Promise<Result<boolean>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/paymentReversal',
    data: {
      reqData: params
    },
  })
}

/**
 * 判断是否为管理员
 */
export async function isAdmin(): Promise<Result<boolean>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/isAdmin',
    data: {},
  })
}

export async function uploadFile(url: string, data: any) {
  return uploadAxios({
    method: "PUT",
    url,
    data,
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export async function uploadFileFromInput(url: string, file: any) {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("input file is null")
    } else {
      const reader = new FileReader();
      reader.onload = async function(event) {
        try {
          const fileData = event.target!.result;
          const ret = await uploadFile(url, fileData)
          resolve(ret)
        } catch (e) {
          reject(e)
        }
      };

      reader.readAsArrayBuffer(file);
    }
  })
}