// 资产管理
import { defaultAxios } from "./index";
import { Result, ResultWithPager } from "../types/common";
import {
  JDFAccountBalanceQueryResult, CalculateFeeParam, CalculateFeeResult,
  QueryAccountFundFlowListParam, QueryAccountFundFlowListItem,
  QueryExchangeRateTrendParam, QueryExchangeRateTrendItem,
  QueryVaAccountListParam, QueryVaAccountListItem,
  QueryVaAccountDetailParam, QueryVaAccountDetailResult,
  ModifyVaAccountNickNameParam,
  CreateAccountParam, CreateAccountResult,
  CurrencyExchangeParam, CurrencyExchangeResult,
  QueryRealTimeExchangeRateParam, QueryRealTimeExchangeRateResult,
  ExchangeQuoteParam, ExchangeQuoteResult,
  QueryTradeOrderDetailParam, QueryTradeOrderDetailResult,
  QueryCompanyInfoParam, QueryCompanyInfoResult
} from '../models/api/assets';

/**
 * @description: JDF账户余额查询
 * 接口地址： https://joyspace.jd.com/pages/MXQZhkZ8XhL6RytDCVng
 */
export async function jdfAccountBalanceQuery(): Promise<Result<JDFAccountBalanceQueryResult>> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/jdfAccountBalanceQuery`
  })
}

/**
 * @description: VA账户流水查询
 * 接口地址： https://joyspace.jd.com/pages/krWmTLzqlSoA3RxvfQbs
 */
export async function queryAccountFundFlowList(params: QueryAccountFundFlowListParam): Promise<ResultWithPager<QueryAccountFundFlowListItem[]>> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/queryAccountFundFlowList`,
    data: {
      reqData: params
    }
  })
}

/**
 * @description: 查询计费信息
 * 接口地址： https://joyspace.jd.com/pages/3ELHi6ioRnNk5vjkjYQ9
 */
export async function calculateFee(params: CalculateFeeParam): Promise<Result<CalculateFeeResult>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/calculateFee`,
    data: {
      reqData: params
    }
  })
}


/**
 * @description: 查询实时汇率趋势
 * 接口地址： https://joyspace.jd.com/pages/3ELHi6ioRnNk5vjkjYQ9
 */
export async function queryExchangeRateTrend(params:QueryExchangeRateTrendParam): Promise<Result<QueryExchangeRateTrendItem[]>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/queryExchangeRateTrend`,
    data: {
      reqData: params
    }
  })
}

/**
 * @description: 查询实时汇率
 * 接口地址： https://joyspace.jd.com/pages/PpgAkFLttnUcItyHyHZQ
 */
export async function queryRealTimeExchangeRate(params:QueryRealTimeExchangeRateParam): Promise<Result<QueryRealTimeExchangeRateResult>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/queryRealTimeExchangeRate`,
    data: {
      reqData: params
    }
  })
}

/**
 * @description: 汇率询价
 * 接口地址： https://joyspace.jd.com/pages/UoPsgQXFbaAyeZoNiWlU
 */
export async function exchangeQuote(params:ExchangeQuoteParam): Promise<Result<ExchangeQuoteResult>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/exchangeQuote`,
    data: {
      reqData: params
    }
  })
}

/**
 * @description: 查询VA账户(全球收款账户)列表
 * 接口地址： https://joyspace.jd.com/pages/so8NhvDUUufJRy9ywrto
 */
export async function queryVaAccountList(params:QueryVaAccountListParam): Promise<ResultWithPager<QueryVaAccountListItem[]>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/queryVaAccountList`,
    data: {
      reqData: params
    }
  })
}


/**
 * @description: 查询VA账户(全球收款账户)详情
 * 接口地址： https://joyspace.jd.com/pages/3jFRSSZc0GfbOSDa0Gm3
 */
export async function queryVaAccountDetail(params:QueryVaAccountDetailParam): Promise<Result<QueryVaAccountDetailResult>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/queryVaAccountDetail`,
    data: {
      reqData: params
    }
  })
}

/**
 * @description: 修改VA账户昵称
 * 接口地址： https://joyspace.jd.com/pages/SATfUstTriBkxajkHUFM
 */
export async function modifyVaAccountNickName(params: ModifyVaAccountNickNameParam): Promise<Result<boolean>>{
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/modifyVaAccountNickName`,
    data: {
      reqData: params
    }
  })
}

/**
 * @description: 开户 VA账户
 * 接口地址： https://joyspace.jd.com/pages/o1RS7oACucEzOBumKA74
 */
export async function createAccount(params: CreateAccountParam): Promise<Result<CreateAccountResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/createAccount',
    data: {
      reqData: params
    },
  })
}

/**
 * @description: 货币汇兑
 * 接口地址： https://joyspace.jd.com/pages/GfpLTABucbGJNqwjFdR6
 */
export async function currencyExchange(params: CurrencyExchangeParam): Promise<Result<CurrencyExchangeResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/currencyExchange',
    data: {
      reqData: params
    },
  })
}


/**
 * @description: 交易单详情
 * 接口地址： https://joyspace.jd.com/pages/FThKv93RIH0gyTL0iXg0
 */
export async function queryTradeOrderDetail(params: QueryTradeOrderDetailParam): Promise<Result<QueryTradeOrderDetailResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/queryTradeOrderDetail',
    data: {
      reqData: params
    },
  })
}


/**
 * @description: 企业信查询
 * 接口地址： https://joyspace.jd.com/pages/mckqon9jOKCEUICxy3Bx
 */

export async function queryCompanyInfo (): Promise<Result<QueryCompanyInfoResult>> {
  return defaultAxios({
    method: "post",
    url: '/gw2/generic/jdfPortal/h5/m/queryCompanyInfo',
    data: {},
  })
}