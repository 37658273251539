import {EntryPathEnum} from "../constants/env"
import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router"
import {tr} from "../i18n"
import {
  showAccountOpeningModal,
  showIsAdmin
} from "../services/AccountOpeningModal.ts";
import {
  AccountOpeningInterceptorRoutes,
  IsAdminInterceptorRoutes
} from "./rules/main.ts";


export function createRouterByEntry(entry: EntryPathEnum, routes: Array<RouteRecordRaw>) {
  const router = createRouter({
    history: createWebHistory(entry),
    routes,
  })

  router.afterEach(async (to, from) => {
    if (__DEV__) {
      return Promise.resolve()
    }
    if (to.fullPath === from.fullPath) {
      return
    }   
    const isAdmin = await showIsAdmin(to, from)

    if (isAdmin) {
      // @ts-ignore
      if (to && AccountOpeningInterceptorRoutes.indexOf(to.name) < 0) {
        return Promise.resolve()
      }
      return showAccountOpeningModal(to, from)
    } else {
      return false
    }
  })

  router.afterEach((to) => {
    if (to.meta.title) {
      // @ts-ignore
      document.title = tr(to.meta.title)
    }
  })

  return router
}

