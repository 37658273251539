import axios from "axios"
import type {AxiosInstance} from "axios"
import {
  defaultAxiosConfig,
  InterceptorRequestError,
  InterceptorRequestEncryptionOk,
  InterceptorRequestRiskData,
  InterceptorResponseError,
  InterceptorResponseOk,
  InterceptorRequestUuid,
  InterceptorRequestLog,
  InterceptorRequestTimeCost, InterceptorResponseDecryptionOk,
} from "./axios"

const defaultAxios: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  ...defaultAxiosConfig,
});

const uploadAxios: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  // ...defaultAxiosConfig,
});

// Axios *request* 拦截器是后 use 的先执行：https://github.com/axios/axios/pull/1041
defaultAxios.interceptors.request.use(InterceptorRequestEncryptionOk, InterceptorRequestError)
defaultAxios.interceptors.request.use(InterceptorRequestRiskData, InterceptorRequestError)
defaultAxios.interceptors.request.use(InterceptorRequestLog, InterceptorRequestError)
defaultAxios.interceptors.request.use(InterceptorRequestUuid, InterceptorRequestError)
defaultAxios.interceptors.request.use(InterceptorRequestTimeCost, InterceptorRequestError)
defaultAxios.interceptors.response.use(InterceptorResponseDecryptionOk, InterceptorResponseError)
defaultAxios.interceptors.response.use(InterceptorResponseOk, InterceptorResponseError)

export { defaultAxios, uploadAxios }