import {fixedAmount} from "./decimal.ts";
import {formatNumber} from "./NumberFormat.ts";
import { DEBOUNCE_WAIT_TIME } from '../constants/page';
import lodashDebounce from 'lodash.debounce';

export function isAxiosTimeoutErrorMessage(str: string) {
  return /timeout of \d+ms exceeded/.test(str)
}
export function isAxiosNetworkErrorMessage(str: string) {
  return "Network Error" === str
}
export function isAxiosAbortErrorMessage(str: string) {
  return "Request aborted" === str
}

export function isUndefined(value: any) {
  return typeof value === "undefined"
}
export function isNumber(value: string) {
  return /^\d+$/.test(value)
}
export function isFunction(value: any) {
  return typeof value === "function"
}

export function uuid_v4() {
  if (!isUndefined(window.crypto) && isFunction(window.crypto)) {
    return crypto.randomUUID()
  } else {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    )
  }
}

export function shortUuid() {
  return uuid_v4().substring(0, 8)
}

// 防抖
export const debounce = (cb: (...args: any[]) => void) => lodashDebounce(cb, DEBOUNCE_WAIT_TIME);

/**
 * 统一格式化金额
 * 1. 转换输入为定点数，保留两位小数
 * 2. 使用 Inet.NumberFormat 格式化为千分位，精度超出回退不格式化
 * @param value
 */
export function formatAmount(value: string | number) {
  return formatNumber(value, 2)
}

export function readCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}