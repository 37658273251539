import enUS from './locales/en-us.json'
import zhCN from './locales/zh-cn.json'
import {createI18n} from "vue-i18n";
import {GlobalLocale} from "./constants/locale";

export type MessageSchema = typeof enUS
export type MessageKey = keyof MessageSchema

const storeLang = localStorage.getItem('lang')

export const i18n = createI18n<MessageSchema, GlobalLocale>({
  locale: storeLang || GlobalLocale.Zh,
  messages: {
    [GlobalLocale.Zh]: zhCN,
    [GlobalLocale.En]: enUS,
  }
})

export function setI18nLanguage(lang: GlobalLocale) {
  i18n.global.locale = lang
}

export function tr(key: MessageKey) {
  return i18n.global.t(key)
}
