import {message} from "ant-design-vue";
import { MessageKey, tr } from "../i18n";

export function showErrorMessage(content?: string, duration?: number) {
    message.error({
      class: "error-message",
      content: content || "操作失败",
      duration
    })
}


const i18n_message = {
  error(contentKey: MessageKey, duration?: number) {
    const content = tr(contentKey);
    message.error({
      content,
      duration,
    });
  },
  success(contentKey: MessageKey, duration?: number) {
    const content = tr(contentKey);
    message.success({
      content,
      duration,
    });
  },
  info(contentKey: MessageKey, duration?: number) {
    const content = tr(contentKey);
    message.info({
      content,
      duration,
    });
  },
  warning(contentKey: MessageKey, duration?: number) {
    const content = tr(contentKey);
    message.warning({
      content,
      duration,
    });
  },
};

export { i18n_message };