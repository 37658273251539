import { ref, onMounted, computed, Ref, watch } from 'vue';
import { checkAccountOpeningProgress } from '../api/biz';
import { BusinessApiCode } from '../constants/enums';

interface UserStatusValue {
  status: Ref<string>,
  isLoading: Ref<boolean>,
  isRealNamed: Ref<boolean>,
  isPWDSetted: Ref<boolean>,
  isAccountOpened: Ref<boolean>,
  query: () => void
}

// 是否已实名
const checkIsRealNamed = (sta: string) => sta.charAt(3) === '1';
// 是否已设置过密码
const checkIsPWDSetted = (sta: string) => sta.charAt(2) === '1';
// 是否已开户
const checkIsAccountOpened = (sta: string) => sta.charAt(0) === '1';

const useUserStatus = ():UserStatusValue =>  {
  // 使用"位"进行表示，每一位表示对应状态是否存在，如 0001 和 0000 表示已实名和未实名，其他状态逻辑同理。最终的状态码值为4位的字符串

  // REAL_NAME_Verified(1 << 0, "未实名"), // 0000
  // REAL_NAME_Verified(1 << 0, "已实名"), // 0001
  // PASSWORD_SET(1 << 1, "已设置交易密码"), // 0010
  // APPLY_EXCEEDED(1 << 2, "申请超过限制"), // 0100
  // ACCOUNT_EXISTED(1 << 3, "账户已存在"); // 1000

  // 举例1：已实名 + 已设置交易密码 + 未超过开户申请限制 + 已开户的返回结果为
  // 1011
  // 举例2: 已实名 + 未设置交易密码 + 未超过开户申请限制 + 未开户的返回结果为
  // 0001
  const status = ref<string>('');
  const isLoading = ref(true);
  // 是否已开户
  const isAccountOpened = ref(false);
  // 是否已实名
  const isRealNamed = ref(false);
  // 是否已设置过密码
  const isPWDSetted = ref(false);

  // 请求用户状态
const getUserStatus = async() => {
    try {
      const { code, data } = await checkAccountOpeningProgress();
      if (code === BusinessApiCode.SUCCESS) {
        status.value = data;
      }
    } finally {
      isLoading.value = false;
    }
  }
  // 是否已开户
  // const isAccountOpened = computed(() => checkIsAccountOpened(status.value));
  // // 是否已实名
  // const isRealNamed = computed(() => checkIsRealNamed(status.value));
  // // 是否已设置过密码
  // const isPWDSetted = computed(() => checkIsPWDSetted(status.value));

  watch(status, (newStatus) => {
    // 是否已开户
    isAccountOpened.value = checkIsAccountOpened(newStatus);
    // 是否已实名
    isRealNamed.value = checkIsRealNamed(newStatus);
    // 是否已设置过密码
    isPWDSetted.value = checkIsPWDSetted(newStatus);
    console.log('useUserStatus: status changed', newStatus, isAccountOpened.value, isRealNamed.value, isPWDSetted.value)
  })

  onMounted(() => {
    getUserStatus();
  })

  const query = () => {
    getUserStatus();
  }

  return {
    status,
    isLoading,
    isRealNamed,
    isPWDSetted,
    isAccountOpened,
    query
  }
}

export {
  useUserStatus,
  checkIsAccountOpened,
  checkIsPWDSetted,
  checkIsRealNamed,
};