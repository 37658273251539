// https://joyspace.jd.com/pages/osfg4FZiXd5pl4zKTvzx
// 开户 createAccount 弹窗内用这个
export const enum AccountOpeningEnum {
  NoRealName = "01", // 01 未实名
  NoPassword = "02", // 02  未设置密码
  ApplyExceeded = "03", // 03 申请次数超过限制
  ApplyFailed = "04", // 04 申请失败
  RealNamePass = "05", // 05 已实已设置过密码
  Success = "06",  // 06 申请JDF成功
}

// 查询开户进度 checkAccountOpeningProgress  点击查看全部用这个
// 使用"位"进行表示，每一位表示对应状态是否存在，如 0001 和 0000 表示已实名和未实名，其他状态逻辑同理。最终的状态码值为4位的字符串

// REAL_NAME_Verified(1 << 0, "未实名"), // 0000
// REAL_NAME_Verified(1 << 0, "已实名"), // 0001
// PASSWORD_SET(1 << 1, "已设置交易密码"), // 0010
// APPLY_EXCEEDED(1 << 2, "申请超过限制"), // 0100
// ACCOUNT_EXISTED(1 << 3, "账户已存在"); // 1000

// 举例1：已实名 + 已设置交易密码 + 未超过开户申请限制 + 已开户的返回结果为
// 1011
// 举例2: 已实名 + 未设置交易密码 + 未超过开户申请限制 + 未开户的返回结果为
// 0001
// todo Delete
// export const enum AccountOpeningProgressEnum {
//   NoRealName = "01", // 01 未实名
//   NoPassword = "02", // 02  未设置密码
//   RealNamePass = "05", // 05 已实已设置过密码 
//   Success = "06",  // 06 申请JDF成功
//   ApplyExceeded = "03", // 03 申请次数超过限制 todo delete
//   ApplyFailed = "04", // 04 申请失败 createAccount todo delete
// }


// queryCompanyInfo 企业信息查询 实名状态枚举
// https://joyspace.jd.com/pages/mckqon9jOKCEUICxy3Bx
export const enum RealStatusEnum {
  Unverified = 10,
  Verified = 20,
  Expired = 30,
}

export const enum OnWayApplyStatusEnum {
  Pending = 100,
  Withdrawn = 110,
  Rejected = 120,
  Passed = 200,
}