// import '@formatjs/intl-numberformat/polyfill'
import {toFixedDecimal} from "./decimal.ts";
import {SgmAlertCode, sgmError} from "./sgm.ts";

export function isOverflow(num: string) {
  return Number(num) + "" !== num
}

export function formatNumber(num: string | number, precision = 2) {
  const n = typeof num === 'string' ? Number(num) : num
  if (typeof Intl === 'undefined') {
    // 不支持 Intl.NumberFormat
    return num
  }
  try {
    const r = toFixedDecimal(num.toString(), precision)
    if (r === "") {
      return num
    } else {
      return new Intl.NumberFormat("en", {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      }).format(r)
    }
  } catch (e: any) {
    sgmError(SgmAlertCode.ERROR, "formatNumber: " + e.message)
  }
}

// numb.toLocaleString();