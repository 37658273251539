import { AxiosError, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from "axios"
import { GW, Result } from "../types/common"
import {
  isAxiosAbortErrorMessage,
  isAxiosNetworkErrorMessage,
  isAxiosTimeoutErrorMessage,
  shortUuid
} from "../utils"
import { SgmAlertCode, sgmError, sgmInfo, sgmWarn } from "../utils/sgm"
import { Gateway, BusinessApiCode, RiskControlApiCode } from "../constants/enums"
import { goTo } from "../utils/location"
import {showErrorMessage} from "../utils/message.ts";
import { message as antMessage } from 'ant-design-vue';
import {gwDecrypt, gwEncrypt} from "./gw-encrypt.ts";
import zhCN from '../locales/zh-cn.json';
import { MessageKey, tr } from '../i18n.ts';

export const defaultAxiosConfig = {
  timeout: 10000,
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
}

function getMessageByCode(code: string, defaultMsg?: string) {
  const msgKey = `errorCode.${code}`;
  if (zhCN[msgKey as MessageKey]) return tr(msgKey as MessageKey);
  return defaultMsg || tr('errorCode.common.exception');
}

function getUsefulMessage(data: GW<Result<any>>) {
  let message = data.resultMsg;
  // let message = getMessageByCode(data.resultCode)
  if (data.msg !== "fallbackmsg" && data.resultMsg === "系统现在有点忙，稍后再试试~") {
    /*
    {
      msg: "已超系统限流峰值，请稍后再试~",
      resultMsg: "系统现在有点忙，稍后再试试~"
    }
    */
    message = data.msg
  }
  return getMessageByCode(data.resultCode, message);
}

// 响应拦截器
// 1. 处理网关 resultCode 异常状态
// 2. 处理业务 resultData.code 异常状态
// 3. 解包 axios 响应 data 数据
// 4. 不返回业务异常状态，上层无须 catch 异步请求
/**
 * 
 * @param response axios 请求返回
 * @returns 
 */
export async function InterceptorResponseOk(response: AxiosResponse<GW<Result<any>>>) {
  const { data, config } = response
  const reportData = {
    url: config.url,
    // @ts-ignore
    uuid: config.__uuid__,
    // @ts-ignore
    cost: new Date().getTime() - config.__time__,
    data,
  }
  let message = getUsefulMessage(data)

  if (__DEV__) {
    if (config.url) {
      message += " " + config.url.slice(config.url.lastIndexOf("/") + 1)
    }
  }

  // 大于1s的请求，上报
  if (reportData.cost > 2000) {
    sgmWarn(SgmAlertCode.RespTimeTooLong, {
      url: config.url,
      // @ts-ignore
      uuid: config.__uuid__,
      cost: reportData.cost,
    })
  }

  // 处理网关异常状态
  // https://joyspace.jd.com/pages/f4xP0aRi06YbXAShIWCo
  switch (data.resultCode) {
    case Gateway.Code801:
        // 未登录
      goTo("/login?return=" + encodeURIComponent(location.href))
      return Promise.resolve({} as AxiosResponse<GW<Result<any>>>)
    case Gateway.Code0:
      // 正常
      break
    case Gateway.Code_1:
      // 异常
      showErrorMessage(message)
      sgmError(SgmAlertCode.Gateway, reportData)
      break
    default:
      showErrorMessage(message)
      // 业务异常
      sgmError(SgmAlertCode.Gateway, reportData)
      return Promise.reject<any>({})
  }

  if (!data.resultData) {
    sgmError(SgmAlertCode.DataEmpty, reportData)
    return Promise.reject<any>({})
  }

  // if (config && config.url) {
  //   if (config.url.indexOf(__MPSF__) > -1) {
  //     if (data.resultData.code !== SuccessCodeB || isUndefined(data.resultData.data)) {
  //       sgmError(SgmAlertCode.Biz_Error, reportData)
  //     } else {
  //       sgmInfo(SgmAlertCode.Res_Info, reportData)
  //     }
  //   }
  //   if (config.url.indexOf(__SAP__) > -1) {
  //     if (data.resultData.code !== SuccessCode || isUndefined(data.resultData.data)) {
  //       sgmError(SgmAlertCode.Biz_Error, reportData)
  //     } else {
  //       sgmInfo(SgmAlertCode.Res_Info, reportData)
  //     }
  //   }
  // }

  if (
    data.resultData.code !== BusinessApiCode.SUCCESS
    && data.resultData.code !== BusinessApiCode.SUCCESS_NO_DATE
    && data.resultData.code !== BusinessApiCode.RECORD_NOT_EXIST
    && data.resultData.code !== RiskControlApiCode.SUCCESS
  ) {
    if (data.resultData.msg) {
      if (data.resultData.code !== 'PTS400001' && data.resultData.code !== 'PTS700006') {
        antMessage.error(getMessageByCode(data.resultData.code, data.resultData.msg));
      }
    }
  }

  return Promise.resolve(data.resultData)
}
export async function InterceptorResponseError(err: AxiosError<GW<any>>) {
  const { response } = err

  if (isAxiosError(err)) {
    if (isAxiosTimeoutErrorMessage(err.message)) {
      showErrorMessage(tr('errorCode.common.timeout'))
    } else if (isAxiosNetworkErrorMessage(err.message)) {
      showErrorMessage(tr('errorCode.common.networkError'))
    } else if (isAxiosAbortErrorMessage(err.message)) {
      showErrorMessage(tr('errorCode.common.networkCancel'))
    } else {
      if (response && response.data) {
        showErrorMessage(getMessageByCode(response.data.resultCode))
      } else {
        showErrorMessage(tr('errorCode.common.networkError'))
      }
    }
  } else {
    if (response && response.data) {
      showErrorMessage(response.data.resultMsg)
    } else {
      showErrorMessage(tr('errorCode.common.exception'))
    }
  }
  sgmError(SgmAlertCode.RespError, err)
  return Promise.reject(err)
}

function getApiLastName(url?: string) {
  if (!url) return ""

  let idx = url.lastIndexOf("/")
  if (idx === -1) return ""

  let action = url.substring(idx + 1)
  if (!action) return ""

  return action
}
/**
 * 使用 API 路径最后一部分来判断是否需要加密
 * @param url API 路径: "/gw2/generic/sap/h5/m/queryRechargeType"
 */
function shouldEncryptApi(url?: string) {
  if (!url) return false
  if (!import.meta.env.VITE_ENT_ENCRYPT_API_LIST) return false

  const action = getApiLastName(url)

  let apis = import.meta.env.VITE_ENT_ENCRYPT_API_LIST.split(",").map((s: string) => s.trim())

  // if (__DEV__) {
  //   console.log("matched: ", import.meta.env.VITE_ENT_ENCRYPT_API_LIST, action)
  //   console.log("yes: ", apis.indexOf(action) !== -1)
  // }

  return apis.indexOf(action) !== -1
}

/**
 * 使用 API 路径最后一部分来判断是否需要加入设备指纹参数
 * @param url API 路径: "/gw2/generic/sap/h5/m/withdraw"
 */
function shouldRiskApi(url?: string) {
  if (!url) return false

  const action = getApiLastName(url)

  let apis = import.meta.env.VITE_ENT_RISK_API_LIST.split(",").map((s: string) => s.trim())

  return apis.indexOf(action) !== -1
}

export async function InterceptorResponseDecryptionOk(response: AxiosResponse<GW<Result<any>>>) {
  const { data, config } = response

  // @ts-ignore
  if (config.__encrypted__ && data.aks) {
      try {
        // @ts-ignore
        const decrypted = await gwDecrypt(data.resultData)
        if (decrypted) {
          data.resultData = JSON.parse(decrypted)
          if (!__PRD__) {
            const action = getApiLastName(config.url)
            console.log(`接口出参解密后[${action}]：`, data.resultData)
          }
        } else {
          sgmError(SgmAlertCode.ENCRYPT, "接口出参解密失败：找不到私钥")
        }
      } catch (e: any) {
        sgmError(SgmAlertCode.ENCRYPT, e)
      }
  }

  return Promise.resolve(response)
}

/*
 * 入参加密拦截器，通过配置环境变量：VITE_ENT_ENCRYPT_API_LIST
 * @example VITE_ENT_ENCRYPT_API_LIST=queryRechargeType,queryRechargeType2
 */
export async function InterceptorRequestEncryptionOk(config: InternalAxiosRequestConfig) {
  if (shouldEncryptApi(config.url)) {
    if (!__PRD__) {
      const action = getApiLastName(config.url)
      console.log(`接口入参加密前[${action}]：`, config.data)
    }
    try {
      const ciphertext = await gwEncrypt(JSON.stringify(config.data || ""))
      if (ciphertext) {
        // @ts-ignore
        config.__encrypted__ = true
        config.data = {
          a: 1,
          b: "SM",
          c: ciphertext
        }
        if (__DEV__) {
          // console.log("接口入参加密后：", config.data)
        }
      } else {
        sgmError(SgmAlertCode.ENCRYPT, "接口入参加密失败：找不到公钥")
      }
    } catch (e: any) {
      sgmError(SgmAlertCode.ENCRYPT, e)
    }
  }
  return config
}
/**
 * 入参加入设备指纹拦截器，通过配置环境变量：VITE_ENT_RISK_API_LIST
 * @example VITE_ENT_RISK_API_LIST=withdraw,currencyExchange
 */
export function InterceptorRequestRiskData(config: InternalAxiosRequestConfig) {
  const { jsToken } = window.deRiskData || {};
  if (!jsToken) {
    sgmError(SgmAlertCode.DeRisk, '未找到设备指纹--' + JSON.stringify(window.deRiskData));
  }
  if (shouldRiskApi(config.url)) {
    config.data = {
      reqData: {
        ...config.data.reqData,
        eid: jsToken
      }
    }
  }
  return config
}

export const InterceptorRequestError = function (err: AxiosError<GW<any>>) {
  return Promise.reject(err)
}

export async function InterceptorRequestUuid(config: InternalAxiosRequestConfig) {
  // @ts-ignore
  config.__uuid__ = shortUuid()
  return config
}
export async function InterceptorRequestTimeCost(config: InternalAxiosRequestConfig) {
  // @ts-ignore
  config.__time__ = new Date().getTime()
  return config
}
export async function InterceptorRequestLog(config: InternalAxiosRequestConfig) {
  const reportData = {
    url: config.url,
    // @ts-ignore
    uuid: config.__uuid__,
    param: config.data,
  }
  // sgmInfo(SgmAlertCode.Req_Info, reportData)
  return config
}