import { EntryPathEnum } from "../constants/env"
import { createRouterByEntry } from "./common"
import { rules } from "./rules/main"

const router = createRouterByEntry(EntryPathEnum.Base, rules)

export function historyBack() {
  router.back()
}

export function historyReplace(path: string, query: Record<string, any> = {}) {
  router.replace({
    path,
    query,
  })
}
export function historyPush(path: string, query: Record<string, any> = {}) {
  router.push({
    path,
    query,
  })
}

export { router }
