import {SuccessCodeA} from "../constants/env"
import {getTobsSMPublicKey} from "./common.ts";

let PUBLIC_KEY: string = ""

export async function initTobsPublicKey() {
  if (PUBLIC_KEY !== "") return PUBLIC_KEY

  try {
    const ret = await getTobsSMPublicKey()
    if (ret.publicKey) {
      PUBLIC_KEY = ret.publicKey
    } else {
      PUBLIC_KEY = ""
    }
  } catch (error) {
    PUBLIC_KEY = ""
    console.warn("initAksPublicKey error", error)
  }
}

export async function gwEncrypt(str: string) {
  await initTobsPublicKey()

  if (!PUBLIC_KEY) {
    return ""
  }

  // const ret = await getAksPublicKey()
  return window.SummerCryptico.encryptData(PUBLIC_KEY, str)
}

export async function gwDecrypt(str: string) {
  return window.SummerCryptico.decryptData(str)
}
