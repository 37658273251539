import getMiniDecimal, { toFixed } from "@rc-component/mini-decimal"

export function toFixedDecimal(amount: string, precision = 2) {
  return toFixed(amount, ".", precision)
}
export function decimalAdd(a: string, b: string, precision = 2) {
  return toFixedDecimal(getMiniDecimal(a).add(b).toString(), precision)
}
// 减法
export function decimalSubtract(a: string, b: string, precision = 2) {
  return toFixedDecimal(getMiniDecimal(a).add(getMiniDecimal(b).negate().toString()).toString(), precision)
}
// 乘法
export function decimalMulti(a: string, b: string, precision = 2) {
  return toFixedDecimal(getMiniDecimal(a).multi(b).toString(), precision)
}
export function fixedAmount(amount: string, precision = 2) {
  return toFixedDecimal(getMiniDecimal(amount).toString(), precision)
}
