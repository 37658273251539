import dayjs from 'dayjs';

// 防抖函数延迟毫秒
export const DEBOUNCE_WAIT_TIME = 300;

// 对象存储图片地址前缀
export const OSS_URL_PREFIX = '//jdpay.s3.cn-north-1.jdcloud-oss.com/pay-c-fintech';

// 最大页数 用于将列表接口一次性全返回
export const MAX_PAGE_SIZE = 9999;

// 时间预设
export const DEFAULT_RANGE_PRESETS = [
  { label: '最近一周', value: [dayjs().add(-7, 'd'), dayjs()] },
  { label: '最近一个月', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: '最近三个月', value: [dayjs().add(-90, 'd'), dayjs()] },
];