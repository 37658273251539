
import { h } from 'vue';
import { Modal } from 'ant-design-vue';
import type { ModalFuncProps } from 'ant-design-vue';
import FTButton from '../components/Button/FTButton.vue';
import { OSS_URL_PREFIX } from '../constants/page';

type PREPARED_IMG_TYPE = 'shield' | 'password' | 'account';
// 内置了几张图片
const PREPARED_IMG: Record<PREPARED_IMG_TYPE, string> = {
  shield: `${OSS_URL_PREFIX}/newuser-status-shield.png`,
  password: `${OSS_URL_PREFIX}/newuser-status-password.png`,
  account: `${OSS_URL_PREFIX}/newuser-status-account.png`,
}

interface TipModalProps extends ModalFuncProps {
  description?: string // 正文描述
  imgUrl?: string // 右上角图片url
  onCancelAfter?: () => void // 取消后的回调
}

// plain 普通弹窗 - 标题前面没有icon，其他类型都会有
type ModalMethodName = 'info' | 'success' | 'error' | 'warning' | 'confirm' | 'plain';

/**
 * 用于简单的信息提示
 */
class TipModal {
  info(props: TipModalProps) {
    this.handleModal(props, 'info');
  }

  success(props: TipModalProps) {
    this.handleModal(props, 'success');
  }
  error(props: TipModalProps) {
    this.handleModal(props, 'error');
  }

  warning(props: TipModalProps) {
    this.handleModal(props, 'warning');
  }

  confirm(props: TipModalProps) {
    this.handleModal(props, 'confirm');
  }

  // 没有icon显示的弹窗
  plain(props: TipModalProps) {
    this.handleModal(props, 'plain');
  }

  handleModal(props: TipModalProps, methodName: ModalMethodName) {
    const { content: pcontent, ...restProps } = props;
    const {
      cancelText, okText = '确定', description, onOk, onCancel, onCancelAfter, class: classname,
      width = 448, imgUrl
    } = restProps;
    let optVnodes = [];
    let midContent;
    const imageUrl = (imgUrl && PREPARED_IMG[imgUrl as PREPARED_IMG_TYPE]) || imgUrl;
    // content 内容部分
    if (pcontent) {
      midContent = pcontent;
    } else {
      midContent = imageUrl ? [
        h('span', { class: ['tipModal-corner-icon'], style: { backgroundImage: `url(${imageUrl})` } }),
        h('div', { class: ['fin-p-14-gray', 'tipModal-desc'] }, description)
      ] : [
        h('div', { class: ['fin-p-14-gray', 'tipModal-desc'] }, description)
      ];
    }
    if (cancelText) {
      // 取消按钮
      optVnodes.push(h(FTButton, {
        type: 'secondary',
        onClick: () => {
          modal.destroy();
          onCancel && onCancel();
          onCancelAfter && onCancelAfter();
        }
      }, () => cancelText));
    }
    // 确定按钮
    optVnodes.push(h(FTButton, {
      onClick: () => {
        onOk && onOk();
        modal.destroy();
      }
    },  () => okText));
    const content = h(
      'div',
      // @ts-ignore
      [ midContent, h('div', { class: 'tipModal-opt' }, optVnodes) ]);
    const coverProps = {
      footer: null,
      content,
      width,
      class: `${classname ? `${classname} ` : ""}tipModal tipModal-${methodName}`,
      centered: true
    }
    const method = methodName === 'plain' ? 'info' : methodName;
    const modal = Modal[method]({
      ...props,
      ...coverProps
    });
  }
}

export const modal = new TipModal();