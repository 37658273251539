export const enum EntryPathEnum {
  Base = "/",
  Admin = "/admin",
}

// sap success
export const SuccessCode = "000000"

export const SuccessCodeA = "00000"

// mpsf success
export const SuccessCodeB = "0000"
