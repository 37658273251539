import { defaultAxios } from "./index"
import { Result, RequestParam } from "../types/common"
import { UploadFileParam, UploadFileResponse, QueryEnumResult, GetPreSignedUrlParam, GetPreSignedUrlResult } from '../models/api/common';

/**
 * 生成国密公钥
 */
export async function getAksPublicKey(): Promise<Result<{ pubKey: string }>> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/sap/h5/m/getAksPublicKey`,
    data: {},
  })
}

/**
 * 网关生成国密公钥
 */
export async function getTobsSMPublicKey(): Promise<{ publicKey: string }> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/getTobsSMPublicKey`,
    data: {},
  })
}


/*
 * 上传接口
 */
export async function uploadFile(params: RequestParam<UploadFileParam>): Promise<Result<UploadFileResponse>> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/uploadFile`,
    data: params,
  })
}


/*
 * 获取文件上传预签名url
 */
export async function getPreSignedUrl (params: GetPreSignedUrlParam): Promise<Result<GetPreSignedUrlResult>> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/getPreSignedUrl`,
    data: {
      reqData: params
    },
  })
}


/*
 * 查询基础枚举数据
 * 接口文档： https://joyspace.jd.com/pages/LJvjL99EgDltH9dsULZt
 */
export async function getBasicData(): Promise<Result<QueryEnumResult>> {
  return defaultAxios({
    method: "post",
    url: `/gw2/generic/jdfPortal/h5/m/getBasicData`,
  })
}